import axios from '@axios'

export default {
    namespaced: true,
    state: {
        companies:[]
    },
    getters: {},
    mutations: {
        SET_SELECTED_COMPANY(state, val) {
            state.companies = val
        },
    },
    actions: {
        fetchUsers(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`${process.env.VUE_APP_BASE_URL}/api/Job`, queryParams)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchCompany(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`${process.env.VUE_APP_BASE_URL}/api/Company`, queryParams)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchCompanyId(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${process.env.VUE_APP_BASE_URL}/api/Job/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addUser(ctx, userData) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`${process.env.VUE_APP_BASE_URL}/api/Job/create`, userData)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        editUser(ctx, userData) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`${process.env.VUE_APP_BASE_URL}/api/Job`, userData)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
  
        delete(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`${process.env.VUE_APP_BASE_URL}/api/Job/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        getUsersList(ctx) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`${process.env.VUE_APP_BASE_URL}/api/User/simple-list`, {
                        pageSize: 2000,
                        currentPage: 1,
                        sortExpression: null,
                        includeDeletedRecords: false,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
   
     
    },
}