import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useUsersList() {
  // Use toast
  const toast = useToast()
  const assigneeOptions = ref([]);
  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'title',label: 'Title', sortable: true },
    { key: 'company',label: 'Company', sortable: true },
    { key: 'description',label: 'Description', sortable: true },
    { key: 'actions', sortable: false },
  ]
  const perPage = ref(9999999)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)

  const fetchData = ref([]);

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    fetchUsers();
  }

  watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {

    refetchData()
  })

  const fetchUsers = (ctx) => {
    store
      .dispatch('job/fetchUsers', {
        pageSize: perPage.value,
        currentPage: currentPage.value,
        sortExpression: sortBy.value,
        sortDirection: isSortDirDesc.value?"desc":"asc",
       //searchEmail:searchQuery.value,
       SearchName:searchQuery.value,
      })
      .then(response => {
       
        const { items, totalCount } = response.data.data
        
       
        fetchData.value =items.sort((a,b) => (a.percent> b.percent)? -1 : ((b.percent> a.percent)? 1 : 0))
        totalUsers.value = totalCount
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  fetchUsers()
  store
      .dispatch('job/fetchCompany', {
        pageSize: 20000,
        currentPage: 1
      })
      .then(response => {
       
        const { items } = response.data.data

        store.commit('job/SET_SELECTED_COMPANY', items)
      
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })

      store
      .dispatch("job/getUsersList", {
        pageSize: 2000,
        currentPage: 1,
        sortExpression: null,
      })
      .then((response) => {
        const { data } = response.data;
        assigneeOptions.value = data;
      })
      .catch((error) => {
        for (const [key, value] of Object.entries(error.response.data.errors)) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "error",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text:value,
          },
        });
        }
      });

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    fetchUsers,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    assigneeOptions,
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,
    fetchData,
    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  }
}
